<app-form-submit-state
  *ngIf="formSubmitted"
  [messageSuccess]="messageSuccess"
></app-form-submit-state>

<form
  (ngSubmit)="submit()"
  [formGroup]="contactUsForm"
  #myform="ngForm"
  class="form-container"
  *ngIf="!formSubmitted"
>
  <div class="form-row">
    <div class="form-input-container">
      <label aria-label class="form-label">Full Name *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <input
          formControlName="fullName"
          class="form-input"
          matInput
          placeholder="e.g. Jane Doe"
        />
      </mat-form-field>
      <mat-error
        *ngIf="
          contactUsForm.get('fullName')?.hasError('required') &&
          myform.submitted
        "
        >Full Name Is A Required Field</mat-error
      >
      <mat-error
      *ngIf="
        contactUsForm.get('fullName')?.hasError('pattern') &&
        myform.submitted
      "
      >Name Contains Invalid Characters</mat-error
    >
    </div>
    <div class="form-input-container">
      <label aria-label class="form-label">Phone Number *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <input
          formControlName="phoneNumber"
          class="form-input"
          matInput
          placeholder="e.g. 0834098041"
          (focus)="onFocus()"
        />
      </mat-form-field>
      <mat-error
        *ngIf="
          contactUsForm.get('phoneNumber')?.hasError('required') &&
          myform.submitted
        "
        >Phone Number Is A Required Field</mat-error
      >
      <mat-hint
        class="hint"
        *ngIf="
          contactUsForm.get('phoneNumber')?.dirty
            ? contactUsForm.get('phoneNumber')?.invalid
            : null
        "
        >Example +27163742632</mat-hint
      >
    </div>
  </div>
  <div class="form-row">
    <div class="form-input-container">
      <label aria-label class="form-label">Tracking number</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <input
          formControlName="trackingNumber"
          class="form-input"
          matInput
          placeholder="e.g. D631007486585"
        />
      </mat-form-field>
    </div>
    <div class="form-input-container">
      <label aria-label class="form-label"
        >Are you the sender or receiver *</label
      >
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <mat-select
          formControlName="type"
          placeholder="e.g. Receiver"
          floatlLabel="never"
        >
          <mat-option *ngFor="let type of types" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error
        *ngIf="
          contactUsForm.get('type')?.hasError('required') && myform.submitted
        "
        >Sender/Receiver Is A Required Field</mat-error
      >
    </div>
  </div>
  <div class="form-row">
    <div class="form-input-container">
      <label aria-label class="form-label">Email Address *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <input
          formControlName="email"
          class="form-input"
          matInput
          placeholder="e.g. janedoe@email.com"
        />
      </mat-form-field>
      <mat-error
        *ngIf="
          contactUsForm.get('email')?.hasError('required') && myform.submitted
        "
        >Email Is A Required Field</mat-error
      >
      <mat-hint
        class="hint"
        *ngIf="
          contactUsForm.get('email')?.dirty
            ? contactUsForm.get('email')?.invalid
            : null
        "
        >Invalid Email Format</mat-hint
      >
    </div>
    <div class="form-input-container">
      <label aria-label class="form-label">Select Query Type *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <mat-select
          formControlName="queryType"
          placeholder="e.g. Track My Parcel"
          floatlLabel="never"
        >
          <mat-option *ngFor="let type of queryTypes" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error
        *ngIf="
          contactUsForm.get('queryType')?.hasError('required') &&
          myform.submitted
        "
        >Query Type Is A Required Field</mat-error
      >
    </div>
  </div>
  <div class="form-row">
    <div class="form-input-container-single">
      <label aria-label class="form-label">Your Message *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-message"
        appearance="outline"
        [color]="'accent'"
      >
        <textarea
          class="form-input"
          matInput
          formControlName="message"
          placeholder="Additional information or comments"
          rows="4"
          [cols]="20"
        ></textarea>
      </mat-form-field>
      <mat-error
        *ngIf="
          contactUsForm.get('message')?.hasError('required') && myform.submitted
        "
        >Message Is A Required Field</mat-error
      >
    </div>
  </div>

  <ng-container *ngIf="!loading; else loadingTemplate">
    <div class="form-row">
      <button type="submit" class="form-button" mat-flat-button>Contact</button>
    </div>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="form-row">
      <mat-spinner [diameter]="50" [color]="spinnerColour"></mat-spinner>
    </div>
  </ng-template>
</form>
