import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { EmailDetails } from '../../contact-forms.model';
import { ContactFormsService } from '../../services/contact-forms.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit, OnDestroy {
  formEventSubject: Subject<boolean> = new Subject<boolean>();
  private eventsSubscription!: Subscription;
  constructor(private service: ContactFormsService) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.eventsSubscription = this.service.formStored.subscribe((eventType) => {
      console.log('get to component');
      this.formEventSubject.next(eventType);
    });
  }

  submitContactForm(formData: EmailDetails): void {
    this.service.sendMail(formData);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.eventsSubscription.unsubscribe();
  }
}
