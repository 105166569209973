import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from './containers/contact-us/contact-us.component';
import {
  RECAPTCHA_V3_SITE_KEY,
  ReCaptchaV3Service,
  RecaptchaV3Module,
} from 'ng-recaptcha';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { ContactFormsService } from './services/contact-forms.service';
import { ContactFormsRoutingModule } from './contact-forms-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactUsFormComponent } from './components/contact-us-form/contact-us-form.component';
import { HttpClientModule } from '@angular/common/http';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormSubmitStateComponent } from './components/form-submit-state/form-submit-state.component';

@NgModule({
  declarations: [
    ContactUsComponent,
    ContactUsFormComponent,
    FormSubmitStateComponent,
  ],
  imports: [
    CommonModule,
    ContactFormsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
})
export class ContactFormsModule {
  static forRoot(): ModuleWithProviders<ContactFormsModule> {
    return {
      ngModule: ContactFormsModule,
      providers: [
        ReCaptchaV3Service,
        ContactFormsService,
        {
          provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
          useValue: { duration: 2500 },
        },
      ],
    };
  }
}
