import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { noop, Observable, Subscription, take } from 'rxjs';

import { EmailDetails, SelectTypes } from '../../contact-forms.model';
import { ThemePalette } from '@angular/material/core';
import { ContactFormsService } from '../../services/contact-forms.service';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactUsFormComponent implements OnInit, OnDestroy {
  @Output() sendMail: EventEmitter<EmailDetails> = new EventEmitter();
  private eventsSubscription!: Subscription;
  @Input() events: Observable<boolean> = new Observable<boolean>();
  formSubmitted: boolean = false;
  messageSuccess!: boolean;
  loading: boolean = false;
  spinnerColour: ThemePalette = 'accent';

  @ViewChild('myform') private myform!: NgForm;

  contactUsForm = this.formBuilder.group({
    fullName: ['', [
        Validators.required,
        Validators.pattern(/^[^0-9@#$%^&*()\[\]{}_+=<>\\\/?,."]+$/),
      ]
    ],
    trackingNumber: [''],
    type: ['', [Validators.required]],
    queryType: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
      ],
    ],
    phoneNumber: [
      '',
      [Validators.required, Validators.pattern('^[+][0-9]{11}$')],
    ],
    message: ['', [Validators.required]],
  });

  types: SelectTypes[] = [
    { value: 'Sender', viewValue: 'Sender' },
    { value: 'Receiver', viewValue: 'Receiver' },
  ];

  queryTypes: SelectTypes[] = [
    { value: 'General Query', viewValue: 'General Query' },
    { value: 'Track My Parcel', viewValue: 'Track My Parcel' },
    { value: 'SMS Not Received', viewValue: 'SMS Not Received' },
    { value: 'Lost/Missing Parcel', viewValue: 'Lost/Missing Parcel' },
    { value: 'Parcel Reroute Request', viewValue: 'Parcel Reroute Request' },
    { value: 'Other', viewValue: 'Other' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private contactFormService: ContactFormsService
  ) {}

  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe((event) => {
      this.formSubmitted = true;
      event ? (this.messageSuccess = true) : (this.messageSuccess = false);
      if (event) {
        this.myform.resetForm();
      }
    });

    this.contactFormService.resetForm.pipe(take(1)).subscribe((reset) => {
      this.formSubmitted = false;
      this.loading = false;
    });
  }

  onFocus() {
    setTimeout(() => {
      !this.contactUsForm.get('phoneNumber')?.value
        ? this.contactUsForm.get('phoneNumber')?.patchValue('+27')
        : noop;
    }, 200);
  }

  submit() {
    const emailContent: EmailDetails = {
      fullName: this.contactUsForm.value.fullName!,
      phoneNumber: this.contactUsForm.value.phoneNumber!,
      trackingNumber: this.contactUsForm.value.trackingNumber!,
      type: this.contactUsForm.value.type!,
      email: this.contactUsForm.value.email!,
      queryType: this.contactUsForm.value.queryType!,
      message: this.contactUsForm.value.message!,
    };

    if (this.contactUsForm.valid) {
      this.loading = true;
      this.sendMail.emit(emailContent);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.eventsSubscription.unsubscribe();
  }
}
