<ng-container *ngIf="messageSuccess; else elseTemplate">
  <div>
    <h2>Success</h2>
    <p>
      Email sent successfully. A PAXI support agent will review your request and
      respond to your email as soon as possible. Response times are generally
      within 24 working hours, depending on volumes.
    </p>
    <p>Thank you for your interest in PAXI.</p>

    <button mat-flat-button (click)="reset()">Back to Form</button>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div>
    <h2>Error</h2>
    <p>Sorry, email send failed.</p>
    <p>
      Sorry, email send failed. Please contact us via WhatsApp or telephone to
      assist you. Apologies for the inconvenience.
    </p>
    <button mat-flat-button (click)="reset()">Back to Form</button>
  </div>
</ng-template>
