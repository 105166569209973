<div class="contact-us-container">
  <!-- <h2 class="contact-us-header">How can we help you?</h2>
  <div class="contact-us-sub-header">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mauris
    amet, felis ornare a eget. Dui augue nunc porta morbi varius dictum nisl,
    aliquam.
  </div> -->
  <div class="contact-us-form-container">
    <app-contact-us-form
      [events]="formEventSubject"
      (sendMail)="submitContactForm($event)"
      class="contact-us-form"
    ></app-contact-us-form>
  </div>
</div>
